@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --welcome-logo-size: 12rem;
}


.noScrollBar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.welcomePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 10rem; */
  height: 100vh;
  background-color: #ece2c5;
}

.logoCard {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  padding: 4rem;
  gap: 4rem;
  border-radius: 100vw;
  /* background: #f5eedb; */
  background: #ece2c5;
  box-shadow: 20px 20px 60px #c9c0a7,
    -20px -20px 60px #ffffe3;
  overflow: hidden;
}

.logo {
  width: var(--welcome-logo-size);
  aspect-ratio: 1;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.logoCard-content {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: auto;
  gap: 1rem;
}

.logoCard-content h1, h3 {
  margin: 0;
  color: #333333;
}

.logoCard-content>h1 {
  font-size: 3rem;
  line-height: 100%;
}

.logoCard-content>h3 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  line-height: 100%;
}

@media (max-width: 1088px) {
  :root {
    --welcome-logo-size: 8rem;
  }

  .logoCard {
    flex-direction: column;
    padding: 3rem;
    gap: 2rem;
  }

  .logoCard-content {
    flex-direction: row-reverse;
    width: auto;
    height: 15rem;
  }

  .logoCard-content h1, .logoCard-content h3 {
    writing-mode: vertical-rl;
  }

  .logoCard-content>h1 {
    font-size: 2.25rem;
    line-height: 100%;
  }

  .logoCard-content>h3 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1rem;
    line-height: 100%;
  }
}

/* Body Page */
.bodyPage {
  position: relative;
  width: 100%;
  z-index: 100;
}

/* Section: All Projects */

.sectionAllProjects {
  display: flex;
  flex-direction: column;
  padding: 64px;
  max-width: 1920px;
  margin: 0 auto;
}