@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --welcome-logo-size: 12rem;
}


.noScrollBar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.welcomePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 10rem; */
  height: 100vh;
  background-color: #ece2c5;
}

.logoCard {
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  padding: 4rem;
  grid-gap: 4rem;
  gap: 4rem;
  border-radius: 100vw;
  /* background: #f5eedb; */
  background: #ece2c5;
  box-shadow: 20px 20px 60px #c9c0a7,
    -20px -20px 60px #ffffe3;
  overflow: hidden;
}

.logo {
  width: 12rem;
  width: var(--welcome-logo-size);
  aspect-ratio: 1;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.logoCard-content {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: auto;
  grid-gap: 1rem;
  gap: 1rem;
}

.logoCard-content h1, h3 {
  margin: 0;
  color: #333333;
}

.logoCard-content>h1 {
  font-size: 3rem;
  line-height: 100%;
}

.logoCard-content>h3 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.5rem;
  line-height: 100%;
}

@media (max-width: 1088px) {
  :root {
    --welcome-logo-size: 8rem;
  }

  .logoCard {
    flex-direction: column;
    padding: 3rem;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .logoCard-content {
    flex-direction: row-reverse;
    width: auto;
    height: 15rem;
  }

  .logoCard-content h1, .logoCard-content h3 {
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
  }

  .logoCard-content>h1 {
    font-size: 2.25rem;
    line-height: 100%;
  }

  .logoCard-content>h3 {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1rem;
    line-height: 100%;
  }
}

/* Body Page */
.bodyPage {
  position: relative;
  width: 100%;
  z-index: 100;
}

/* Section: All Projects */

.sectionAllProjects {
  display: flex;
  flex-direction: column;
  padding: 64px;
  max-width: 1920px;
  margin: 0 auto;
}
.projShowcase {
    width: 100%;
    margin: 5rem 0;
}

.projShowcase-cateTitle {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 100%;
}

.projShowcase-itemContainer {
    /* margin: 4rem 0; */
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.projShowcase-item {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: all .3s cubic-bezier(.5, 0, 0, 1);
    overflow: hidden;
}

.projShowcase-itemCover {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    transition: all .3s cubic-bezier(.5, 0, 0, 1);
    z-index: -1;
}

.projShowcase-item.folded:hover {
    border-radius: 4rem;
}

.projShowcase-item.unfolded {
    border-radius: 8rem;
}

.projShowcase-item.unfolded:hover {
    border-radius: 4rem;
}

.projShowcase-item.folded.superNarrow:hover {
    border-radius: 2rem;
}

.projShowcase-item.unfolded.superNarrow {
    border-radius: 4rem;
}

.projShowcase-item.unfolded.superNarrow:hover {
    border-radius: 2rem;
}

/* Information shown when folded */

.projShowcase-foldedInfo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    color: #000;
    opacity: 1;
    transition: opacity .15s .15s;
}

.projShowcase-foldedInfo.unfolded {
    opacity: 0;
    transition: opacity .15s;
}

.projShowcase-foldedInfo>h1 {
    flex-grow: 1;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    /*Text ellipsis*/
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.projShowcase-foldedInfo>h3 {
    flex-shrink: 0;
    margin: 0;
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed', sans-serif;
}

.projShowcase-foldedInfo>div {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: 60px;
}

.projShowcase-item.superNarrow .projShowcase-foldedInfo {
    padding: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.projShowcase-item.superNarrow .projShowcase-foldedInfo>h1 {
    font-size: 1.5rem;
}

.projShowcase-item.superNarrow .projShowcase-foldedInfo>h3 {
    font-size: 1.5rem;
}

/* Information shown when unfolded */
.projShowcase-fullInfo {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    max-width: 80%;
    padding: 1.5rem 3rem;
    opacity: 0;
    color: #000;
    background-color: #f5eedba0;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border-radius: 0;
    border-bottom-right-radius: 6rem;
    transition: opacity .15s;
}

.projShowcase-item.unfolded:hover .projShowcase-fullInfo {
    border-bottom-right-radius: 2rem;
}

.projShowcase-fullInfo.unfolded {
    opacity: 1;
    transition: opacity .15s .15s, border-bottom-right-radius .3s cubic-bezier(.5, 0, 0, 1);
}

.projShowcase-fullInfo span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
}

.projShowcase-fullInfo h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    line-height: 120%;
}

.projShowcase-item.unfolded:hover .projShowcase-fullInfo h1 {
    text-decoration: underline;
}

.projShowcase-fullInfo h3 {
    flex-shrink: 0;
    align-self: flex-start;
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Barlow Semi Condensed', sans-serif;
    line-height: 120%;
}

.projShowcase-fullInfo>p {
    margin: 0;
    margin-top: .5rem;
    font-size: 1rem;
    font-family: 'Barlow Semi Condensed', sans-serif;
    line-height: 120%;
}

.projShowcase-item.superNarrow .projShowcase-fullInfo {
    bottom: 1rem;
    right: 1rem;
    padding: 1rem 1.5rem;
    border-bottom-right-radius: 3rem;
}

.projShowcase-item.unfolded.superNarrow:hover .projShowcase-fullInfo {
    border-bottom-right-radius: 1rem;
}

.projShowcase-item.superNarrow .projShowcase-fullInfo span {
    grid-gap: 1rem;
    gap: 1rem;
}

.projShowcase-item.superNarrow .projShowcase-fullInfo h1 {
    font-size: 1.5rem;
}

.projShowcase-item.superNarrow .projShowcase-fullInfo h3 {
    font-size: 1.5rem;
}

/* Show All ... */

.projShowcase-showAll {
    display: flex;
    align-self: flex-end;
    justify-content: end;
    align-items: stretch;
    grid-gap: .5rem;
    gap: .5rem;
    flex-shrink: 0;
    cursor: pointer;
}

.projShowcase-showAll>div {
    width: 1.5rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: #ddd0aa;
    transition: background-color .15s;
}

.projShowcase-showAll:hover>div {
    background-color: #e34848;
}
